<template>
  <div class="bbs-sidebar-box" v-if="totals">
    <div class="side-topic">
      <div class="side-tit">
        <div class="side-tit-left">{{ title }}</div>
        <div class="side-tit-right">
          <span class="a" @click="moreJump">{{ $t("XB_More") }}</span>
          <!-- 更多 -->
        </div>
      </div>
      <div class="side-topic-list">
        <div class="side-topic-item" v-for="item in list" :key="item.topicId">
          <span @click="jump(item)">#{{ item.topicName }}#</span>
        </div>
        <a-empty v-if="!totals" />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/locale/i18n";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { getTopicList } from "@/api/bbs";
export default {
  name: "sideTopic",
  props: {
    title: {
      type: String,
      default: i18n.global.t("bbs.topic_2"),
      // 话题
    },
    newWindow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      list: [],
      totals: 0,
    });

    getTopicList({
      page: 1,
      pageSize: 5,
      sortKey: "hot",
      sort: 1,
      status: 1,
    }).then((res) => {
      state.list = res.data.list || [];
      state.totals = res.data.totals || 0;
    });

    const moreJump = () => {
      if (props.newWindow) {
        window.open(`/bbs/topicList?ddtab=true`);
      } else {
        router.push({
          path: "/bbs/topicList",
        });
      }
    };

    const jump = (item) => {
      if (props.newWindow) {
        window.open(`/bbs/topic?ddtab=true&topicId=${item.topicId}`);
      } else {
        router.push({
          path: "/bbs/topic",
          query: {
            topicId: item.topicId,
          },
        });
      }
    };

    return {
      ...toRefs(state),
      moreJump,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.side-tit {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 24px;
  .mixinFlex(space-between);
  &-left {
    font-size: 16px;
    color: #202020;
  }
  &-right {
    .a {
      font-size: 14px;
      color: #666666;
      transition: color 0.3s;
      cursor: pointer;
      &:hover {
        color: @color-theme;
      }
    }
  }
}
.side-topic {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  &-list {
    padding: 15px 20px;
  }
  &-item {
    .mixinEllipsis(24px);
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      font-size: 14px;
      color: #666666;
      transition: color 0.3s;
      cursor: pointer;
      &:hover {
        color: @color-theme;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
